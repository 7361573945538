<script lang="ts">
  import type { ReviewModel } from '@24hbs/utils';
  // Utils
  import { stringToSlug } from "@24hbs/utils";
  import { humanize, textEllipsis } from '$lib/utils/formatter';
  // Components
  import { ReviewScore } from '$lib/components/atoms/ReviewScore';

  export let wide = false;
  export let review: ReviewModel;
  export let truncateBody: boolean = false;
  export let truncateTitle: boolean = false;
  export let hideCourseTitle: boolean = false;

  const courseTypeUrl = (review: ReviewModel): string =>{
    return review.courseType.toLowerCase() === "master full time con stage"?stringToSlug("master full time"):stringToSlug(review.courseType);
  }
</script>

{#if review}
  <div class="course-review-card" class:wide>
    {#if !hideCourseTitle}
      <div class="course-review-course-content">
        <div class="course-review-title-type">
          <p class="course-review-master-type">{review.courseType}</p>
          <h5 class="course-review-master-title">
            <a class="course-review-master-link" href={courseTypeUrl(review)}>
              {truncateTitle
                ? textEllipsis(review.courseTitle, 50)
                : review.courseTitle}</a>
          </h5>
        </div>
        <p class="course-review-course-data">
          <strong>{review.totalReviews}</strong> recensioni — Trust score:
          <strong>{review.trustScore}</strong>
        </p>
      </div>
    {/if}

    <div class="course-review-card-content">
      {#if review.author}
        <p class="course-review-author">
          {review.author}
        </p>
      {/if}
      <div class="course-review-score">
        <ReviewScore score={review.score} />
        {#if review.date}
          <p class="course-review-time">{humanize(review.date)}</p>
        {/if}
      </div>
      {#if review.reviewTitle}
        <h4 class="course-review-title">{review.reviewTitle}</h4>
      {/if}
      {#if review.reviewContent}
        <p class="course-review-description">
          {truncateBody
            ? textEllipsis(review.reviewContent, 80)
            : review.reviewContent}
        </p>
      {/if}
    </div>
  </div>
{/if}

<style type="text/scss" lang="scss" scoped>
  .course-review-card {
    @apply rounded bg-white shadow-md;
    @apply w-full;
    @apply overflow-hidden;
  }

  .course-review-course-content,
  .course-review-card-content {
    @apply p-4 md:p-6;
  }

  .course-review-course-content {
    @apply relative;
    @apply bg-gray-100;
    @apply space-y-1;
  }

  .course-review-title-type {
    @apply space-y-1;
  }

  .course-review-author {
    @apply font-sans font-semibold text-sm;
    @apply mb-2;
  }

  .course-review-time {
    @apply text-sm md:text-base;
  }

  .course-review-score {
    @apply flex justify-between;
  }

  .course-review-score + .course-review-title {
    @apply mt-5;
  }

  .course-review-title {
    @apply font-sans font-semibold;
    @apply md:truncate;
    @apply text-base md:text-lg;
  }

  .course-review-title + .course-review-description {
    @apply mt-3;
  }

  .course-review-description {
    @apply text-sm;
  }

  .course-review-master-link {
    &:before {
      content: '';
      @apply absolute inset-0;
    }
  }

  .course-review-master-type {
    @apply text-sm md:text-md text-gray-600;
    @apply font-sans font-semibold;
    @apply uppercase tracking-wide;
  }

  .course-review-master-title {
    @apply font-serif font-medium text-lg md:text-2xl;
  }

  .course-review-course-data {
    @apply font-sans text-sm md:text-base;
  }

  /* Wide Variant for Reviews page */
  .wide {
    .course-review-course-content {
      @apply md:flex md:justify-between;
    }
    .course-review-course-data {
      @apply md:self-end;
    }
  }
</style>
